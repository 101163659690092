import log from 'loglevel'
import isString from 'lodash/isString'

log.setDefaultLevel('warn')

function printLog (level, args = []) {
  if (args.length && isString(args[0])) {
    args[0] = `[ bytel-tag-commander ] ${args[0]}`
  }

  return log[level](...args)
}

export default {
  setLevel (...args) {
    return log.setLevel(...args)
  },

  setDefaultLevel (...args) {
    return log.setDefaultLevel(...args)
  },

  debug (...args) {
    return printLog('debug', args)
  },

  info (...args) {
    return printLog('info', args)
  },

  warn (...args) {
    return printLog('warn', args)
  },

  error (...args) {
    return printLog('error', args)
  }
}
