import localforage from 'localforage'
import sessionstoragewrapper from 'localforage-sessionstoragewrapper' 

export async function setDriver () {
  await localforage.defineDriver(sessionstoragewrapper)
}

let store
export function getCache () {
  if (store) {
    return store
  }
  store = localforage.createInstance({
    driver: [sessionstoragewrapper._driver, localforage.LOCALSTORAGE],
    name: 'bytel-tag-commander',
  })
  return store
}
