import get from 'lodash/get'
import has from 'lodash/has'
import map from 'lodash/map'
import each from 'lodash/each'
import pick from 'lodash/pick'
import find from 'lodash/find'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import extend from 'lodash/extend'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import upperFirst from 'lodash/upperFirst'
import intersection from 'lodash/intersection'
import isArray from 'lodash/isArray'

import api from './api'
import { getCache } from './store'

export default {
  getData () {
    return this.getUserType().then(userType => {
      if (userType !== 'CLIENT') return { userType }

      return this.getPersonId().then(personId => {
        if (!personId) return Promise.resolve({})

        return this.getPerson(personId).then(
          person => {
            return this.getContracts(person).then(contracts => ({ userType, person, contracts }))
          },
          () => null
        )
      })
    })
  },

  getRenewEligibility (contractId) {
    return this.getContract(contractId)
      .then(contract => {
        const path = get(contract, '_links.eligibilitesRenouvellement.href')

        if (!path) throw new Error('Missing eligibility link')

        return api.get({ path })
      })
      .then(eligibility => {
        const item = find(get(eligibility, 'items'), { type: 'renouvellement' })

        if (!item) throw new Error('Missing eligibility item')

        return item.statut
      })
      .catch(() => false)
  },

  getContract (contractId) {
    const path = `/contrats/${contractId}`

    return api.get({ path })
  },

  getUserType () {
    return Promise.resolve(
      getCache().getItem('user-type')
    )
  },

  getPersonId () {
    return Promise.resolve(
      getCache().getItem('person-id')
    )
  },

  getType (person) {
    let type = 'non identifié'

    if (!isEmpty(person)) {
      if (get(person, '_links.contratsSignes.href') || get(person, '_links.contratsUtilises.href')) {
        type = 'client'
      } else {
        type = 'prospect'
      }
    }

    return type
  },

  getPerson (id) {
    const path = `/personnes/${id}`

    return api.get({ path }).catch(() => null)
  },

  getContracts (person) {
    const path = get(person, '_links.contratsSignes.href') || get(person, '_links.contratsUtilises.href')

    if (!path) return Promise.resolve([])

    return api.get({ path }).catch(() => ([]))
  },

  getMobileUsage (equipementMobile) {
    const path = get(equipementMobile, 'mobileUsage.produit.href')

    if (!path) return Promise.resolve({})

    return api.get({ path }).catch(() => ({}))
  },

  getContractIds (contracts) {
    contracts = get(contracts, 'items', contracts) || []

    return map(filterActiveContracts(contracts), contract => contract.id)
  },

  getCategory (person, contracts) {
    if (isEmpty(person)) return ''

    contracts = get(contracts, 'items', contracts) || []

    if (isTitulaire(person)) return 'titulaire'
    else if (isDelegated(person, contracts)) return 'délégué'
    else if (isRestricted(person, contracts)) return 'restreint'
  },

  checkContractByContract (contracts, offers) {
    let gamme = []

    gamme = concat(gamme, getFnb(offers))
    gamme = concat(gamme, getFixe(offers))

    each(contracts, contract => {
      if (isFaim(contract)) gamme = concat(gamme, ['FAIM'])
      else if (isCarte(contract)) gamme = concat(gamme, ['CARTE'])
      else if (isTokyo(contract)) gamme = concat(gamme, ['TOKYO'])
      else if (isFb(contract)) gamme = concat(gamme, ['FB'])
    })

    return uniq(gamme)
  }
}

// --------
// HELPER
// --------
function mapOfferIds (offer) {
  return map(get(offer, 'famillesOffre', []), 'idFamilleOffre')
}

function getFnb (offers) {
  let gamme = []

  each(offers, offer => {
    const offerFamilies = get(offer, 'famillesOffre', [])
    const foundOffer = find(offerFamilies, { typeFamilleOffre: `version d'offres` })

    // Finds offer with the new typeFamilleOffre attribute
    if (foundOffer) {
      const offerFamilyLabel = get(foundOffer, 'libFamilleOffre')
      gamme = concat(gamme, [offerFamilyLabel])
    } else {
      const offerId = mapOfferIds(offer)

      if (intersection(offerId, fieldsFnb.SENSATION).length !== 0) gamme = concat(gamme, ['FNB SENSATION'])
      else if (intersection(offerId, fieldsFnb.BYOU).length !== 0) gamme = concat(gamme, ['FNB BYOU'])
      else if (intersection(offerId, fieldsFnb.SIMO).length !== 0) gamme = concat(gamme, ['FNB SIMO'])
    }
  })

  return gamme
}

function getFixe (offers) {
  let gamme = []

  each(offers, offer => {
    const offerFamilies = get(offer, 'famillesOffre', [])
    const foundOffer = find(offerFamilies, { typeFamilleOffre: 'Standing' })

    // Finds offer with the new typeFamilleOffre attribute
    if (foundOffer) {
      const offerFamilyLabel = get(foundOffer, 'libFamilleOffre')
      gamme = concat(gamme, [offerFamilyLabel])
    } else {
      const offerId = mapOfferIds(offer)

      if (intersection(offerId, fieldsFixe.STANDARD).length !== 0) gamme = concat(gamme, ['FIXE STANDARD'])
      else if (intersection(offerId, fieldsFixe.SENSATION).length !== 0) gamme = concat(gamme, ['FIXE SENSATION'])
      else if (intersection(offerId, fieldsFixe.MIAMI).length !== 0) gamme = concat(gamme, ['FIXE MIAMI'])
    }
  })

  return gamme
}

function filterContratEquipementMobibles (contracts) {
  return filter(contracts, contract => isEquipementMobiles(contract))
}

function isEquipementMobiles (contrat) {
  return get(contrat, '_links.equipementsMobiles.href')
}

function filterEquipementMobilesByMobilesUsage (equipementsMobiles) {
  return filter(equipementsMobiles, equipementMobile => isMobileUsage(equipementMobile))
}

function isMobileUsage (equipementMobile) {
  return get(equipementMobile, 'mobileUsage.produit.href')
}

function filterTypeLignesPossedes (contracts) {
  contracts = get(contracts, 'items', contracts) || []

  return filter(contracts, contract => typeLignePossede(contract))
}

function typeLignePossede (contract) {
  const typeLigne = get(contract, 'typeLigne')

  return typeLigne === 'MOBILE' || typeLigne === 'FIXE'
}

function filterActiveContracts (contracts) {
  contracts = get(contracts, 'items', contracts) || []

  return filter(contracts, contract => contractIsActive(contract))
}

function contractIsActive (contract) {
  const status = get(contract, 'statut')

  return status === 'ACTIF' || status === 'SUSPENDU'
}

function isTitulaire (person) {
  return has(person, '_links.contratsSignes.href')
}

function isDelegated (person, contracts) {
  return hasOnlyDelegatedUser(contracts) && !isTitulaire(person)
}

function isRestricted (person, contracts) {
  return hasOnlyRestrictedUser(contracts) && !isTitulaire(person)
}

function hasOnlyDelegatedUser (contracts) {
  contracts = get(contracts, 'items', contracts) || []

  return filter(contracts, hasDelegatedUser).length === contracts.length
}

function hasOnlyRestrictedUser (contracts) {
  contracts = get(contracts, 'items', contracts) || []

  return filter(contracts, hasRestrictedUser).length === contracts.length
}

function hasDelegatedUser (contract) {
  return hasProfileRights(contract, '2')
}

function hasRestrictedUser (contract) {
  return hasProfileRights(contract, '3')
}

function hasProfileRights (contract, rights) {
  return get(contract, 'utilisateur.profilDroits') === rights
}

// ---------------------
// const helper gamme
// ---------------------
const fields = {
  FNB: 'FNB',
  FB: 'FB',
  CARTE: 'CARTE',
  FAIM: 'FAIM'
}

const fieldsFnb = {
  'SENSATION': ['150'],
  'BYOU': ['151', '152'],
  'SIMO': ['342']
}

const fieldsFixe = {
  'STANDARD': ['10017'],
  'SENSATION': ['10018'],
  'MIAMI': ['10019']
}

// ----------------------------

function checkContratType (contrat, type) {
  return checkContrat(contrat) === type
}

function checkContrat (contrat) {
  contrat = get(contrat, 'attributes', contrat)

  if (contrat.partitionSic === 1) {
    return fields.FNB
  }

  if (contrat.partitionSic === 2) {
    if (get(contrat, 'abonnement.libFamilleOffre') === 'CARTE PREPAYEE') {
      return fields.CARTE
    }

    return fields.FB
  }

  return false
}

function isFnb (contrat) {
  return checkContratType(contrat, 'FNB')
}

function isFb (contrat) {
  return checkContratType(contrat, 'FB')
}

function isFaim (contrat) {
  contrat = get(contrat, 'attributes', contrat)

  return get(contrat, 'abonnement.offreDataMobile') === true
}

function isTokyo (contrat) {
  contrat = get(contrat, 'attributes', contrat)

  return get(contrat, 'abonnement.offreTokyo')
}

function isCarte (contrat) {
  return checkContratType(contrat, 'CARTE')
}

function hasOnlyFNB (contrats) {
  return contrats.filter(isFnb).length === contrats.length
}

function hasOnlyFB (contrats) {
  return contrats.filter(isFb).length === contrats.length
}

function hasOnlyCarte (contracts) {
  return hasOnlyType(contracts, 'carte')
}

function hasOnlyType (contracts, type) {
  // Support literal arrays and backbone collections
  contracts = get(contracts, 'models', contracts)

  // Retrieve a list of contracts with the given type
  var filteredContracts = filter(contracts, (contract) => filterByTypeAndStatus(contract, type))

  // Check if both lists have the same length
  return filteredContracts.length === contracts.length
}

function filterByTypeAndStatus (contract, types, filterStatus) {
  var hasContractType = false
  var contractData = get(contract, 'attributes', contract)

  types = isArray(types) ? types : [types]

  // Checking if contract type is in the defined list of contract types for the executed route
  for (var i = 0; i < types.length; i += 1) {
    var type = types[i]

    // Check if a type is negated
    if (type.charAt(0) === '!' && hasCaracteristic(type.substr(1), contractData)) {
      break
    }

    if (type === 'any' || hasCaracteristic(type, contractData)) {
      hasContractType = true
      break
    }
  }

  if (!hasContractType) {
    return false
  }

  // Checking if contract filterStatus is in the defined list of contract filterStatus' for the executed route
  return !filterStatus ||
    filterStatus === 'any' ||
    (filterStatus && this.hasCaracteristic(filterStatus, contractData))
}

function hasCaracteristic (caracteristic, contract) {
  var methodName = 'is' + upperFirst(caracteristic)

  return isFunction([methodName]) && [methodName](contract)
}

