const trackingHistory = {
  shouldEmitEvent: false,
  hasHistory: false,
  setup (options) {
    this.shouldEmitEvent = options.shouldEmitEvent
    this.hasHistory = options.hasHistory
    if (this.hasHistory && !window.hasOwnProperty('trackingHistory')) {
      window.trackingHistory = []
    }
  },
  sendEvent (type, options) {
    const tracking = {
      tracking: options,
      type,
      date: new Date().toISOString()
    }

    if (this.hasHistory) {
      window.trackingHistory.push(tracking)
    }
    if (this.shouldEmitEvent) {
      // eslint-disable-next-line no-undef
      document.dispatchEvent(new CustomEvent('tracking:send', { detail: tracking }))
    }
  }
}
export default trackingHistory
