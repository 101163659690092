import set from 'lodash/set'
import extend from 'lodash/extend'

// Setup default tag commander variables
let defaultTcVars = {
  // -----------------
  // Client data
  // -----------------

  // CLIENT / CDC / CDV / SUPPORT (oauth)
  typeUtilisateur: '',

  // Connecté / Non Connecté (oauth)
  connexion: false,

  // Infos client
  structureClient: {
    idPersonne: '',
    idsContrat: []
  },

  // -----------------
  // Environment data
  // -----------------

  // DEV / BETA / PROD
  env: 'PROD',

  // espace_client / magento / etc
  site: '',

  // -----------------
  // Page data
  // -----------------

  univers: '',

  parcours: '',

  pageCategorie: '',

  page: ''
}

let permanentStore = {}

// Public export
export default {
  /**
   * Store tc_vars data permanently
   *
   * @param  {object} data Data object to store in tc_vars
   * @return {object}      Modified tc_vars object
   */
  store (data) {
    permanentStore = extend(permanentStore, data)
    defaultTcVars = extend(defaultTcVars, permanentStore, data)

    return defaultTcVars
  },

  /**
   * Set tc_vars data temporarily
   *
   * @param  {object} data Data object to store in tc_vars
   * @return {object}      The modified `tc_vars` object
   */
  set (data) {
    // Clear `nomClic`, 'acte' and 'categorieActe' var to prevent data colisions
    set(window.tc_vars, 'nomClic', '')
    set(window.tc_vars, 'acte', '')
    set(window.tc_vars, 'categorieActe', '')
    set(window.tc_vars, 'etapeEcommerce', '')
    set(window.tc_vars, 'pageVariante', '')

    // Extend defaults with existing tc_vars data for multi lib compatibility
    window.tc_vars = extend(defaultTcVars, window.tc_vars, permanentStore, data)

    return window.tc_vars
  }
}
