import extend from 'lodash/extend'
import bytelApi from '@bytel/bytel-api'

import log from './log'

export default {
  async setup (options = {}) {
    log.debug('Setting up bytel-api using options:', options)

    const { host, accessToken, cache } = options

    const bytelOptions = {
      host,
      cache: {
        maxAge: 15 * 60 * 1000,
        key: (req) => `bytel-tag-commander/${req.url}`
      },
      interceptors: {
        authorization: { accessToken }
      }
    }

    bytelOptions.cache = extend(bytelOptions.cache, cache)

    this.get = await bytelApi.setup(bytelOptions)
  },

  get () {
    throw new Error('[ bytel-tag-commander ] Could not call bytel-api, it has not been setup yet')
  }
}
